import axios from 'axios'
const API_URL = 'https://api.remplafacile.fr/api'

const headers = {
    'x-api-key': "dKD:wyACUfTYj!gSE?Pk3RO>)lBb,sSzsa{3MLZ<{82],LF$r7%%vh&$t[qi9|",
};
export async function EmailVerification(token) {
    try {
        const response = await axios.get(API_URL+`/verify-email/${token}`,  {
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        });
        // Handle success response
        return response.data
    } catch (error) {
        return error.response.data
    }
}